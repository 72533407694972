import React from 'react';

import Layout from '../components/Layout';

const About = () => (
  <Layout fullMenu>
    <section id="wrapper">
      <header>
        <div className="innner">
          <h2> What does Best Movers do?</h2>
          <p>
            We take the guess work out of moving and worry about the little
            details so you can relax.
          </p>
          <div className="header-button">
            <a href="#moving-quote" className="get-my-quote">
              Get my quote now
            </a>
          </div>
        </div>
      </header>

      <div className="wrapper">
        <div className="inner">
          <h3 className="major">Who is Best Movers?</h3>
          <p>
            Best Movers is a family owned business gone digital! What started
            out 100 years ago as a few families of hard working immigrants who
            came together from the old country and joined hands to pursue the
            American dream by way of honest work as a local moving company,
            slowly grew into a mid sized business of fathers sons, nieces and
            nephews, and then a bigger long distance moving company, an import
            export business specializing in cheese and wines, and even an
            international freight service, and commercial moving company which
            has shipped millions of tons of goods from here to China and back.
            As the decades have blessed us with the chance to help so many
            folks, and the grandkids got involved it was time to move our
            business to where we could consolidate all of our skills and serve
            as many people as possible.{' '}
          </p>
          <p>
            Now adays we find we are best able to serve our clients by using our
            large network of professional drivers, movers, and contractors to
            help other families achieve their dreams just like we have ours. We
            know today you need movers to ship just a few items. And a few years
            from now you can call us when you need to find the right crew to
            build that add on to your new home, or when your kids go off to
            college and you want to make sure they aren't sleeping on the floor
            like our grandparents did when they came back from the War. When you
            finally open that business you've always dreamed of and need to
            outfit a new warehouse- we'll be there to build the foundation with
            you. Because that's what true success really is. Building the kinds
            of relationships that span for generations.
          </p>
        </div>
      </div>
    </section>
  </Layout>
);

export default About;
